"use server";

import { redirect } from "@solidjs/router";
import { getGiftById } from "../apis/server_apis";
import { GiftCardDetails } from "../types/pluto";
import { getRequestEvent } from "solid-js/web";
import { getCookie } from "vinxi/http";
import { Cookie } from "~/types";

export type PlutoGiftDetailsRouteData = {
  giftDetails?: GiftCardDetails;
};

export const getPlutoGiftDetailsRouteData = async (
  giftId: string
): Promise<PlutoGiftDetailsRouteData> => {
  const requestEvent = getRequestEvent();
  let giftDetails: GiftCardDetails | undefined;

  if (requestEvent) {
    let sid =
      requestEvent?.locals?.sid ??
      getCookie(requestEvent?.nativeEvent, Cookie.SessionId);

    if (!sid) {
      throw redirect("/gifts/login");
    } else {
      giftDetails = await getGiftById(giftId);
    }
  }

  return { giftDetails };
};
